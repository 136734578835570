import React from 'react';
import DefaultForm from './DefaultForm';
import DaForm from './DaForm';

import { locale } from '../../../locale';

export default ({ seo, location }) => {

  return (
    <>
      {locale === 'da'
        ? <DaForm seo={seo} location={location}/>
        : <DefaultForm seo={seo} location={location}/>
      }
    </>
  )
}